import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/html/html5-elements",
  "date": "2015-01-31",
  "title": "HTML5 ELEMENTS",
  "author": "admin",
  "tags": ["development", "html"],
  "featuredImage": "feature.jpg",
  "excerpt": "HTML5 offers new elements for improving semantics of code as well as better document structure. These elements could now be used to replace traditional HTML code like <div id=”nav”> <div class=”header”> <div id=”footer”>"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Here are few examples:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`<section>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<article>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<aside>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<details>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<description>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<nav>`}</inlineCode></li>
    </ul>
    <p><img parentName="p" {...{
        "src": "http://www.w3schools.com/html/img_sem_elements.gif",
        "alt": "HTML5 elements"
      }}></img></p>
    <h2>{`SECTION`}</h2>
    <p>{`W3C Specification:`}</p>
    <p><em parentName="p">{`The section element represents a generic document or application section.`}</em></p>
    <p>{`This sounds a lot like the definition for a div but div has no semantic meaning.`}</p>
    <h2>{`SECTION VS. DIV`}</h2>
    <p>{`A `}<strong parentName="p">{`div`}</strong>{` has no semantic meaning, but the `}<strong parentName="p">{`section`}</strong>{` element does. It’s used for grouping together thematically related content.`}</p>
    <p>{`Example usage of the section tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="section">
    ## The Gallery
    <!-- ... -->
</div>
`}</code></pre>
    <p>{`This can be replaced with a section tag.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<section>
    ## The Gallery
    <!-- ... -->
</section>  
`}</code></pre>
    <h2>{`THE DOCUMENT OUTLINE`}</h2>
    <p>{`The document outline produces an outline summary of an HTML document based on how it is marked up.`}</p>
    <p>{`The following elements have their self-contained outline:`}</p>
    <ul>
      <li parentName="ul">{`Article`}</li>
      <li parentName="ul">{`Aside`}</li>
      <li parentName="ul">{`Nav`}</li>
      <li parentName="ul">{`Section`}</li>
    </ul>
    <p>{`Take the following example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h1>This is the title of the page.</h1>
<section>
    ## This is the title of a sub-section.
</section>  
`}</code></pre>
    <p>{`The output of the above, will look like follows:`}</p>
    <pre><code parentName="pre" {...{}}>{`1. This is the title of the page.
    1.1 This is the title of a sub-section.
`}</code></pre>
    <p>{`We can use `}<strong parentName="p">{`h1`}</strong>{` inside of the section tag, and the document outline is unchanged.`}</p>
    <h2>{`HEADER`}</h2>
    <p>{`W3C Specification:
`}<em parentName="p">{`A group of introductory or navigational aids.`}</em></p>
    <p>{`There can be many different headers on a page. They usually appear at the top of a document or section, but is defined by its content rather than its position.`}</p>
    <p>{`Example usage of the header tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="header">
    <!-- ... -->
</div>  
`}</code></pre>
    <p>{`This can be done in HTML5 using the header tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<header>
<!-- ... -->
</header>   
`}</code></pre>
    <p>{`Example usage of the header tag within a section:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<section>
    <header>
        <h1>The heading of the section</h1>
        This is content in the header.
    </header>
    This is some information within the section.
</section>  
`}</code></pre>
    <h2>{`FOOTER`}</h2>
    <p>{`W3C Specification:
`}<em parentName="p">{`Like the header, the footer element is not position-dependent. It should describe the content it is contained within.`}</em></p>
    <p>{`Example usage of the footer tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="footer">
    <!-- ... -->
</div>
`}</code></pre>
    <p>{`This can be done in HTML5 using the footer tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<footer>
<!-- ... -->
</footer>   
`}</code></pre>
    <p>{`Example usage of the footer tag within a section tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<section>
    <header>
        <h1>The heading of the section</h1>
        This is content in the header.
    </header>
    This is some information within the section.
    <footer>
        By "Author Name"
    </footer>
</section>  
`}</code></pre>
    <h2>{`ASIDE`}</h2>
    <p>{`W3C Specification:
`}<em parentName="p">{`Initially, the HTML5 spec defined the aside element as being “tangentially related to the content surrounding it.”`}</em></p>
    <p>{`The aside now covers various contexts:`}</p>
    <ul>
      <li parentName="ul">{`When used within an article element, the aside contents should be related to that particular article it is contained within.`}</li>
      <li parentName="ul">{`When used outside an article element, the aside contents should be specifically related to the site.`}</li>
    </ul>
    <p>{`An aside element is appropriate when it is used to represent content that is not the primary focus of an article or page, but it is still related to the article or page.`}</p>
    <p>{`Example usage of the aside tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="sidebar">">
    <!-- ... -->
</div>
`}</code></pre>
    <p>{`This can be done in HTML5 using the footer tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<aside>
<!-- ... -->
</aside>    
`}</code></pre>
    <p>{`Example usage of the aside tag within a section tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<section>
    <header>
        <h1>The heading of the section</h1>
        This is content in the header.
    </header>
    This is some information within the section.
    <aside>
        Some secondary information.
    </aside>
    <footer>
        By "Author Name"
    </footer>
</section>  
`}</code></pre>
    <h2>{`NAV`}</h2>
    <p>{`W3C Specification:`}</p>
    <p><em parentName="p">{`The nav element represents a section of a page that links to other pages or to parts within the page: a section with navigation links.`}</em></p>
    <p>{`The nav element is intended for `}<strong parentName="p">{`major navigation.`}</strong></p>
    <p>{`Example usage of the aside tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="nav">">
    <!-- ... -->
</div>
`}</code></pre>
    <p>{`This can be done in HTML5 using the nav tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<nav>
<!-- ... -->
</nav>  
`}</code></pre>
    <h2>{`ARTICLE`}</h2>
    <p>{`W3C Specification:`}</p>
    <p><em parentName="p">{`The article element represents a complete, or self contained, composition in a document, page, application, or site and that is, in principle, independently distributable or reusable, e.g. in syndication.`}</em></p>
    <p>{`The article element is another type of section. It is used for self-contained related content.`}</p>
    <p>{`Some uses for the article tag:`}</p>
    <ul>
      <li parentName="ul">{`A blog post`}</li>
      <li parentName="ul">{`A news story`}</li>
      <li parentName="ul">{`A comment on a post`}</li>
      <li parentName="ul">{`A review`}</li>
    </ul>
    <p>{`Example usage of the article tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="article">">
    <!-- ... -->
</div>
`}</code></pre>
    <p>{`This can be done in HTML5 using the article tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<article>
<!-- ... -->
</article>  
`}</code></pre>
    <h2>{`MAIN`}</h2>
    <p>{`W3C Specification:`}</p>
    <p><em parentName="p">{`The main element represents the main content of the body of a document or application.`}</em></p>
    <p>{`Main should not be allowed in following places:`}</p>
    <ul>
      <li parentName="ul">{`Do not include more than one main element in a document.`}</li>
      <li parentName="ul">{`Do not include the main element inside of an article, aside, footer, header, or nav element`}</li>
    </ul>
    <p>{`Example usage of the main tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="main">">
    <!-- ... -->
</div>
`}</code></pre>
    <p>{`This can be done in HTML5 using the main tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<main>
<!-- ... -->
</main> 
`}</code></pre>
    <h2>{`FIGURE`}</h2>
    <p>{`W3C Specification:`}</p>
    <p><em parentName="p">{`"The figure element represents a unit of content, optionally with a caption, that is self-contained, that is typically referenced as a single unit from the main flow of the document, and that can be moved away from the main flow of the document without affecting the document’s meaning.”`}</em></p>
    <p>{`A common use of the figure tag is for an image within an article:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<figure>
    <img src="image.jpg" alt="My Picture" />
</figure>
`}</code></pre>
    <p>{`Example usage of the figcaption tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<figure>
    <img src="image.jpg" alt="My Picture" />
    <figcaption>This is a caption for the picture.</figcaption>
</figure>
`}</code></pre>
    <h2>{`TIME`}</h2>
    <p>{`W3C Specification:`}</p>
    <p><em parentName="p">{`The time element represents either a time on a 24 hour clock, or a precise date in the proleptic Gregorian calendar, optionally with a time and a time-zone offset.`}</em></p>
    <p>{`The article element is another type of section. It is used for self-contained related content.`}</p>
    <p>{`Some uses for the article tag:`}</p>
    <ul>
      <li parentName="ul">{`A blog post`}</li>
      <li parentName="ul">{`A news story`}</li>
      <li parentName="ul">{`A comment on a post`}</li>
      <li parentName="ul">{`A review`}</li>
    </ul>
    <p>{`Example usage of the time tag: (US time)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`    <time>2015-01-31</time>
`}</code></pre>
    <p>{`We can format the time to “mm/dd/yyyy”:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<time>09/16/2013</time>
`}</code></pre>
    <p>{`We use the DateTime attribute to get our desired format:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<time datetime="2015-01-31">01/31/2015</time>   
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      